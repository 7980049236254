const locale = {
    authScreen: {
        welcome: 'Welcome to SpendWatcher',
        email: 'Email address',
        username: 'Username',
        password: 'Password',
        register: 'Register',
        login: 'Login',
        submit: 'Submit',
    },
    dashboard: {
        monthOverview: '{{0}} overview',
        totalSpent: 'Total spent',
        discretionaryTotal: 'Discretionary total',
        recurringTotal: 'Recurring total',
        topCategories: 'Top discretionary categories',
    },
    spendingData: {
        topCombined: 'Combined total',
        other: 'Remaining total',
        noTopDiscretionaryCategories: 'You have no discretionary spending so far this month.',
        moreLabel: 'See more',
    },
    trends: {
        pageTitle: 'Trends',
        dashboardButton: 'Dashboard',
        // filterButton: 'Filter', TODO:
        yearlyLabel: 'Yearly',
        monthlyLabel: 'Monthly',
        transactionsTitle: 'Discretionary transactions',
        topCategories: 'Top discretionary categories',
        category: 'Category',
        totalAmountSpentHeader: 'Total spent',
        totalCountHeader: 'Transaction count',
        totalPercentageHeader: 'Percentage of total',
        totalCountPercentageHeader: 'Percentage of transactions',
        discretionaryTotalHeader: 'Discretionary total',
        discretionaryTotalCountHeader: 'Discretionary transaction count',
        discretionaryTotalPercentageHeader: 'Discretionary % of total',
        discretionaryTotalCountPercentageHeader: 'Discretionary % of transactions',
        recurringTotalHeader: 'Recurring total',
        recurringTotalCountHeader: 'Recurring transaction count',
        recurringTotalPercentageHeader: 'Recurring % of total',
        recurringTotalCountPercentageHeader: 'Recurring % of transactions',
        total: 'Total',
    },
    general: {
        empty: '--',
        submit: 'Submit',
        cancel: 'Cancel',
        delete: 'Delete',
        confirm: 'Confirm',
        close: 'Close',
        confirmChange: 'Confirm change',
    },
    accounts: {
        addAccount: 'Add account',
        totalAccountValues: 'Accounts total',
        accountNameLabel: 'Account name',
        accountTypeLabel: 'Account type',
        startingAccountValueLabel: 'Current account value',
        annualGrowthRateLabel: 'Annual growth rate',
        fixedRateLabel: 'This growth rate is fixed',
        accountNamePlaceholder: 'Account name',
        submit: 'Submit',
        cancel: 'Cancel',
        amountPlaceholder: '$0.00',
        percentagePlaceholder: '0.00%',
        accountsList: 'Your accounts',
        asOf: 'As of {{0}}',
        manageAccountHeader: 'Manage {{0}}',
        editAccountHeader: 'Edit {{0}}',
        setInactiveHeader: 'Stop tracking this account',
        deleteAccountHeader: 'Delete account',
        editAccountOption: 'Edit account',
        deleteAccountOption: 'Delete account',
        setInactiveOption: 'Stop tracking this account',
        close: 'Close',
        setAccountInactiveTitle: 'Stop tracking "{{0}}"',
        setAccountInactiveDescription:
            'If you have closed this account or otherwise no longer wish to track account values, you can stop tracking it here. This will not delete the account or its data.',
        stopTrackingButton: 'Stop tracking',
        deleteAccountTitle: 'Permanently delete "{{0}}"',
        deleteAccountDescription:
            'This will permanently delete this account and all of its data. If you wish to keep this data, instead consider setting this account as inactive by choosing "I\'ve closed this account".',
        deleteAccountFinalWarning: 'This action cannot be undone.',
        deleteAccountButton: 'Delete account',
        accountsExpectUpdates: '{{0}} account(s) require updates for this month',
        historyHeader: '"{{0}}" history',
        accountHistoryOption: 'History',
        addNewRow: 'Add for {{0}}',
        amountLabel: 'Amount',
        backButton: 'Back',
        accountRequiresUpdateCTA: '{{0}} requires an update',
    },
    transactions: {
        recent: 'Recent transactions',
        noRecentTransactions: 'You do not have any transactions this month.',
        todayLabel: '{{0}} - Today',
        yesterdayLabel: '{{0}} - Yesterday',
        emptyPlaceholder: '--',
        clearSelection: 'Clear selection',
        logExpense: 'Log expense',
        newExpense: 'New expense',
        editExpense: 'Edit expense',
        deleteExpense: 'Permanently delete this expense',
        amountLabel: 'Amount',
        categoryLabel: 'Category',
        notesLabel: 'Notes',
        dateLabel: 'Date of Expense',
        tripLabel: 'Linked Trip',
        notesPlaceholder: 'About your expense',
        amountPlaceholder: '$0.00',
        tripNotice: 'Your current trip, "{{0}}", has already been applied to this transaction.',
    },
    trips: {
        pageLoadingAccessibleText: 'Trips page is loading',
        pageTitle: 'My trips',
        discretionary: 'Discretionary',
        total: 'Total',
        linkedTransactions: 'Linked transactions',
        details: 'Details',
        close: 'Close',
        editTransaction: 'Edit transaction',
        editTripDetails: 'Edit trip details',
        tripsPageErrorTitle: "We couldn't load your trips",
        tripsPageErrorMessage: 'Please try again later.',
        linkedTransactionsErrorTitle: 'Error loading trip transactions',
        linkedTransactionsErrorMessage:
            'We were unable to show you transactions linked to this trip. Please try again later.',
        addTrip: 'Add trip',
        newTrip: 'New trip',
        tripName: 'Trip name',
        tripNamePlaceholder: 'Europe summer trip',
        startDate: 'Start date',
        endDate: 'End date',
        linkedTransactionsEmptyTitle: 'This trip has no linked transactions yet',
        linkedTransactionsEmptyMessage: 'Add transactions to this trip to see them here.',
        deleteTrip: 'Delete {{0}}',
        deleteButtonLabel: 'Permanently delete this trip',
        deleteSpeedBumpHeader: 'Are you sure?',
        deleteSpeedBumpDescription:
            "This will permanently delete this trip, but all linked transactions will remain.  Linked transactions will have their linked trip value set to 'None'.",
    },
    recurringSpending: {
        pageTitle: 'Recurring spending',
        createNew: 'Create recurring expense',
        monthlyTransactions: 'Monthly transactions',
        inactiveTransactions: 'Inactive transactions',
        estimatedMonthlyTotal: 'Estimated monthly total',
        monthActualTotal: `{{0}} actual total`,
        estimatedLabel: 'Estimated: {{0}}',
        fixedLabel: 'Fixed',
        newRecurringExpenseTitle: 'New recurring expense',
        recurringSpendName: 'Expense name',
        newSpendNamePlaceholder: 'Rent payment',
        categoryLabel: 'Category',
        variableExpenseLabel: 'This amount varies',
        variableExpenseDescription: 'The amount of this expense varies from month to month, like a utility bill.',
        monthlyAmountLabel: 'Monthly amount',
        monthlyAmountVariesLabel: 'Estimated monthly amount',
        monthlyAmountPlaceholder: '$0.00',
        deleteSpeedBumpHeader: 'Are you sure?',
        deleteSpeedBumpDescription:
            'This will permanently delete this recurring expense and all of its past data. This means all previous months\' spend data for "{{0}}" will be permanently deleted.',
        finalDeletionWarning: 'This action cannot be undone.',
        edit: 'Edit',
        transactionHistory: 'History',
        permanentlyDelete: 'Permanently delete',
        cancel: 'Cancel',
        chooseOption: 'What would you like to do?',
        editingTransaction: 'Edit "{{0}}"',
        deletingTransaction: 'Delete "{{0}}"',
        markActive: 'Reactive this expense',
        markInactive: 'Mark as inactive',
        setActiveTitle: 'Reactivate this recurring spend',
        setActiveDescription:
            'Reactivating an expense means fixed expenses will resume being automatically logged each month and variable expenses will remind you to provide a monthly update.  Upon activation, the expense will be logged, or an update requested, for the current month.',
        setInactiveTitle: 'Deactivate this recurring spend',
        setInactiveDescription:
            'Deactivating an expense will stop it from being automatically logged each month, if fixed, or requesting monthly updates, if variable. You can reactivate it at any time.',
        historyTitle: '"{{0}}" history',
        spendsRequiringUpdates: '{{0}} recurring expense(s) require updates this month',
        updateRequired: 'Update required',
    },
    recurringTransactionsList: {
        amountSpentLabel: 'Amount spent',
        backButton: 'Back',
        addNewRow: 'Add for {{0}}',
    },
    ACCOUNT_CATEGORIES: {
        CHECKING: 'Checking',
        SAVINGS: 'Savings',
        INVESTING: 'Investment',
        BONDS: 'Bonds',
    },
    SPENDING_CATEGORIES: {
        RESTAURANTS: 'Dining out',
        DRINKS: 'Drinks',
        GROCERIES: 'Groceries',
        ENTERTAINMENT: 'Entertainment',
        EV_CHARGING: 'EV charging',
        TRANSPORTATION: 'Transportation',
        VEHICLE: 'Vehicle',
        FUEL: 'Fuel',
        FITNESS: 'Fitness',
        GROOMING: 'Grooming & self-care',
        BUSINESS: 'Business expense',
        MATERIAL_ITEMS: 'Material items',
        AIRFARE: 'Airfare',
        LODGING: 'Lodging',
        HEALTH: 'Healthcare',
        CLOTHING: 'Clothing',
        GIFTS: 'Gifts & charity',
        EDUCATION: 'Education',
        UTILITIES: 'Utilities',
        HOUSING: 'Housing',
        CANNABIS: 'Cannabis',
        PETS: 'Pets',
        INSURANCE: 'Insurance',
        NICOTINE: 'Nicotine',
        GAMES: 'Games',
        HOBBY: 'Hobbies',
        TREATS: 'Treats',
        TAXES: 'Taxes',
        OTHER: 'Other',
    },
} as const;

export default locale;
